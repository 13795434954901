import React, { FC } from "react";
import styles from './layout.module.scss'
import { Outlet } from "react-router-dom";
import { Footer } from "../_shared/components/Footer/footer.component";
import { Navbar } from "../_shared/components/Navbar/navbar.component";

interface LayoutProps {
    scrollToSection: (sectionRef: React.RefObject<HTMLDivElement>) => void;
    refs: {
      overviewRef: React.RefObject<HTMLDivElement>;
      featuresRef: React.RefObject<HTMLDivElement>;
      pricingRef: React.RefObject<HTMLDivElement>;
    };
  }

  const Layout: FC<LayoutProps> = ({ scrollToSection, refs }) => {
    return (
      <div className={styles.layoutContainer}>
        <Navbar scrollToSection={scrollToSection} refs={refs} />
  
        <div className={styles.main}>
          <Outlet />
        </div>
  
        <Footer />
      </div>
    );
  };
  
  export default Layout;
