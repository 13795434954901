import React from 'react';
import styles from './home.module.scss';
import Dashboard from '../../assets/webp/allDeviceDashboard.webp';
import Feat1 from '../../assets/webp/feat1.webp';
import Feat2 from '../../assets/webp/feat2.webp';
import Feat3 from '../../assets/webp/feat3.webp';
import Feat4 from '../../assets/webp/feat4.webp';
import Pricing from './data/individual_pricing_monthly';

interface HomeProps {
    overviewRef: React.RefObject<HTMLDivElement>;
    featuresRef: React.RefObject<HTMLDivElement>;
    pricingRef: React.RefObject<HTMLDivElement>;
}

const Home: React.FC<HomeProps> = ({ overviewRef, featuresRef, pricingRef }) => {

    return (
        <div className={styles.homePage}>

            <div className={styles.overview} ref={overviewRef}>
                <div className={styles.titleContainer}>
                    <p className={styles.title}>Priveshield for Toshiba Canvio Hard Drives</p>
                    <p className={styles.subTitle}>Effortless protection for your innovation</p>
                </div>

                <div className={styles.heroSection}>
                    <div className={styles.textContainer}>
                        <p className={styles.title}>Privacy, security, and freedom for all your files.</p>
                        <p className={styles.subTitle}>Store and edit files and folders on your favourite Toshiba hard drive with complete peace of mind.</p>
                        <button className={styles.cta}>Try Priveshield</button>
                    </div>

                    <div className={styles.heroImgContainer}>
                        <img src={Dashboard} alt="dashboard" />
                    </div>
                </div>

            </div>
            <div className={styles.features} ref={featuresRef}>
                <p className={styles.titleContainer}>See what you can do with Priveshield</p>

                <div className={styles.featuresWrapper}>

                    <div className={styles.feature}>
                        <div className={styles.textContainer}>
                            <p className={styles.title}>Unlock the power of unique encryption</p>
                            <p className={styles.description}>Priveshield provides built-in protections aganist malware, spam, and ransomware whti end-to-end and device-to- device encryption. Any unauthorized access gets blocked instantly. File-level security ensures each fiel isindividually encrypted.</p>
                        </div>
                        <div className={styles.imgContainer}>
                            <img src={Feat4} alt="feature" />
                        </div>
                    </div>

                    <div className={styles.feature}>
                        <div className={styles.textContainer}>
                            <p className={styles.title}>Relax, your backups are encrypted too</p>
                            <p className={styles.description}>Guarantees your fiels to saty safe, even during a data breach. Also, the cloud storage providers cant' peek into your fiels. Your files stay secure both on your device and inthe cloud, even while transferring them.</p>
                        </div>
                        <div className={styles.imgContainer}>
                            <img src={Feat2} alt="feature" />
                        </div>
                    </div>

                    <div className={styles.feature}>
                        <div className={styles.textContainer}>
                            <p className={styles.title}>Mobile access and a single password to own your data</p>
                            <p className={styles.description}>View and Control files on your favourite iOS and Android devices through our mobile app. Priveshield brings all your accounts together, simplifying access. One Password grants you effortless control over everything.</p>
                        </div>
                        <div className={styles.imgContainer}>
                            <img src={Feat3} alt="feature" />
                        </div>
                    </div>

                    <div className={styles.feature}>
                        <div className={styles.textContainer}>
                            <p className={styles.title}>Safeguard more than just files</p>
                            <p className={styles.description}>Your metadata and indexes remain safely encrypted on Toshiba HD or, sync them seamlessly on your cloud storage.</p>
                        </div>
                        <div className={styles.imgContainer}>
                            <img src={Feat1} alt="feature" />
                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.pricing} ref={pricingRef}>
                <div className={styles.titleContainer}>
                    <p className={styles.title}>Find the plan that's right for you</p>
                </div>

                <div className={styles.cardsContainer}>
                    {Pricing.map((plan, index) => {
                        return (
                            <div className={`${styles.card} ${plan.selected && `${styles.cardHighlight}`}`} key={index}>
                                <p className={styles.planName}>{plan.plan} ({plan.lifespan})</p>
                                <p className={styles.planPrice}>{plan.pricing}</p>
                                <div className={styles.planFeats}>
                                    {plan.text.map((feature, index) => {
                                        return (
                                            <p className={styles.feature} key={index}>{feature}</p>
                                        )
                                    })}
                                </div>
                                <button className={styles.cta}>{plan.buttonText}</button>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export { Home };
