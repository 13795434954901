import {
	createBrowserRouter,
	RouterProvider,
	createHashRouter
} from 'react-router-dom';
import Layout from './Layout/layout';
import { Home } from './Pages/Home/home';
import React, { useRef } from 'react';

const Routing = (props: any) => {
	const AppRouter = window.process ? createHashRouter : createBrowserRouter;

	// Define the refs
	const overviewRef = useRef<HTMLDivElement>(null);
	const featuresRef = useRef<HTMLDivElement>(null);
	const pricingRef = useRef<HTMLDivElement>(null);

	// Define the scroll function
	const scrollToSection = (sectionRef: React.RefObject<HTMLDivElement>) => {
		if (sectionRef.current) {
			sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const routes = AppRouter([
		{
			path: `/`,
			element: (
				<Layout
					scrollToSection={scrollToSection}
					refs={{ overviewRef, featuresRef, pricingRef }}
				/>
			),
			children: [
				{
					path: `/`,
					element: <Home overviewRef={overviewRef} featuresRef={featuresRef} pricingRef={pricingRef} />
				},
			]
		}
	]);

	return <RouterProvider router={routes} />;
};

export default Routing;
