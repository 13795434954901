import React, { useEffect, useRef, useState } from 'react';
import styles from './navbar.module.scss';
import { ReactComponent as PriveshieldLogo } from '../../../assets/svg/priveshield_logo.svg';
import { ReactComponent as ZirohLogo } from '../../../assets/svg/zirohLabs_logo_dark.svg';
import { useDeviceStore } from '../../../StateManager/DeviceStore';
import { AnimatePresence, motion } from 'framer-motion';
import { DEVICE } from '../../constants/constants';
import useGlobalHook from '../../hooks/global.hook';

interface NavbarProps {
  scrollToSection: (sectionRef: React.RefObject<HTMLDivElement>) => void;
  refs: {
    overviewRef: React.RefObject<HTMLDivElement>;
    featuresRef: React.RefObject<HTMLDivElement>;
    pricingRef: React.RefObject<HTMLDivElement>;
  };
}

function Navbar({ scrollToSection, refs }: NavbarProps) {

  const { device } = useDeviceStore();
  const { disableScroll, enablePageScroll } = useGlobalHook();

  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
    document.body.style.overflow = 'unset';
  }, [device]);

  useEffect(() => {
    if (active) {
      disableScroll();
    } else {
      enablePageScroll();
    }
  }, [active]);

  const navRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = navRef.current;

    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        const height = entry.contentRect.height;
        document.documentElement.style.setProperty("--nav_height", `${height}px`);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, []);

  return (
    <nav ref={navRef} className={`${styles.navbar} ${active && `${styles.activeNavbar}`}`}>
      <div className={styles.navContents}>
        <div className={styles.logoContainer}>
          <PriveshieldLogo />
        </div>

        <div className={styles.navOptionsContainer}>
          {device !== DEVICE.MOBILE &&
            <p className={styles.navOption} onClick={() => scrollToSection(refs.overviewRef)}>Overview</p>
          }
          {device !== DEVICE.MOBILE &&
            <p className={styles.navOption} onClick={() => scrollToSection(refs.featuresRef)}>Features</p>
          }
          {device !== DEVICE.MOBILE &&
            <p className={styles.navOption} onClick={() => scrollToSection(refs.pricingRef)}>Pricing</p>
          }

          <p className={styles.cta} onClick={() => { scrollToSection(refs.pricingRef); }}>Download</p>

          {device === DEVICE.MOBILE &&
            <div className={styles.hamburger} onClick={() => { setActive(!active) }}>
              <span className={`${styles.burger} ${active && `${styles.activeBurger1}`}`}></span>
              <span className={`${styles.burger} ${active && `${styles.activeBurger2}`}`}></span>
            </div>
          }
        </div>
      </div>

      <AnimatePresence mode="wait">
        {active &&
          <motion.div
            key="navOptions"
            initial={{ height: 0 }}
            animate={{ height: "78vh" }}
            exit={{ height: 0 }}
            transition={{
              duration: .4,
              ease: "linear",
            }}
            className={styles.navOptions}
          >
            <p className={styles.navOption} onClick={() => { scrollToSection(refs.overviewRef); setActive(false); }}>Overview</p>
            <p className={styles.navOption} onClick={() => { scrollToSection(refs.featuresRef); setActive(false); }}>Features</p>
            <p className={styles.navOption} onClick={() => { scrollToSection(refs.pricingRef); setActive(false); }}>Pricing</p>

            <div className={styles.poweredBy}>
              <p>Powered by</p>
              <ZirohLogo />
            </div>
          </motion.div>
        }
      </AnimatePresence>
    </nav>
  );
}

export { Navbar };
