import React from 'react'
import styles from './footer.module.scss'
import { ReactComponent as InfinityLogo } from '../../../assets/svg/priveshield_logo.svg';
import { ReactComponent as ZirohLogo } from '../../../assets/svg/zirohLabs_logo_dark.svg';

import { ReactComponent as FacebookIcon } from '../../../assets/svg/facebook_icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/svg/linkedin_icon.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/svg/instagram_icon.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/svg/youtube_icon.svg';
import { ReactComponent as XIcon } from '../../../assets/svg/x_icon.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

function Footer() {
    return (
        <div className={styles.footer}>

            <div className={styles.brandingContainer}>
                <InfinityLogo />
                <div className={styles.zirohLogo}>
                    <p>Powered by</p>
                    <ZirohLogo />
                </div>
            </div>

            <div className={styles.linksContainer}>
                <Link to={''} reloadDocument> Privacy Policy </Link>
                <Link to={''} reloadDocument> Terms of Service </Link>
                <Link to={''} reloadDocument> Support </Link>
                <Link to={''} reloadDocument> Contact Us </Link>
            </div>

            <div className={styles.socialMediaContainer}>
                <p className={styles.copyright}>© 2024 Ziroh Labs. All rights reserved.</p>
                <div className={styles.mediaIconsContainer}>
                    <Link to={ROUTES.SOCIAL_MEDIA.FACEBOOK} target="_blank" reloadDocument><FacebookIcon /></Link>
                    <Link to={ROUTES.SOCIAL_MEDIA.LINKEDIN} target="_blank" reloadDocument><LinkedinIcon /></Link>
                    <Link to={ROUTES.SOCIAL_MEDIA.INSTAGRAM} target="_blank" reloadDocument><InstagramIcon /></Link>
                    <Link to={ROUTES.SOCIAL_MEDIA.YOUTUBE} target="_blank" reloadDocument><YoutubeIcon /></Link>
                    <Link to={ROUTES.SOCIAL_MEDIA.X} target="_blank" reloadDocument><XIcon /></Link>
                </div>
            </div>
        </div>
    )
}

export { Footer }