export const ROUTES = {
    HOME: '/',

    SOCIAL_MEDIA:{
        LINKEDIN: 'https://www.linkedin.com/company/zirohlabs/',
        LINKEDIN_BUSINESS: 'http://linkedin.com/showcase/zunuforbusiness',
        FACEBOOK: 'https://www.facebook.com/zirohlabs/',
        X: 'https://x.com/zirohlabs',
        INSTAGRAM: 'https://www.instagram.com/zirohlabs/',
        YOUTUBE: 'https://www.youtube.com/@zirohlabs',
    },
}