import React from 'react';
import logo from './logo.svg';
import './App.scss';
import Routing from './App.routing';
import { useThemeStore } from './StateManager/ThemeStore';
import useAppHook from './App.hook';

function App() {

  const{} = useAppHook();
  const {theme} = useThemeStore();
  return (
    <div className={`App ${theme}`}>
      <Routing />
    </div>
  );
}

export default App;
