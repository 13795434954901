
const Pricing: {
  plan: string;
  lifespan: string;
  pricing: string;
  selected: boolean;
  text: string[];
  buttonText: string;
}[] = [
    {
      plan: 'Free',
      lifespan: 'Limited',
      pricing: '$0',
      selected: false,
      text: [
        `Encrypted and Privacy Preserving
      Storage & Search Functions for 15
      GB Users Confidential & Privacy
      Preserving Files in Toshiba HDD `,

        `Encrypted and Privacy Preserving
      Files Sync. & Storage Function for 15
      GB of Users Confidential Files to the
      User’s Owned Cloud Storage. of
      (Google Drive, Dropbox, Box, and
      OneDrive)`,

        `Single Cloud Storage Account
      Supported `,

      ],
      buttonText: 'Download',
    },
    {
      plan: 'Premium (500GB)',
      lifespan: '3 Years',
      pricing: '$8.00',
      selected: false,
      text: [
        `Encrypted and Privacy Preserving
      Storage & Search Functions for 500
      GB of Users Confidential & Privacy
      Preserving Files in Toshiba HDD`,

        `Encrypted and Privacy Preserving
      Files Sync. & Storage Function for
      500 GB Users Confidential Files to
      the User’s Owned Cloud Storage.
      (Google Drive, Dropbox, Box, and
      OneDrive)`,

        `Multiple Cloud Storage Accounts
      Supported     
      `,

      ],
      buttonText: 'Download',
    },
    {
      plan: 'Premium (1TB)',
      lifespan: '3 Years',
      pricing: '$10.00',
      selected: false,
      text: [
        `Encrypted and Privacy-Preserving
      Storage & Search Functions for 1 TB
      of Users’ Confidential & Privacy
      Preserving Files in Toshiba HDD`,

        `Encrypted and Privacy Preserving
      Files Sync. & Storage Function for
      1 TB Users Confidential Files to
      the User’s Owned Cloud Storage.
      (Google Drive, Dropbox, Box, and
      OneDrive)`,

        `Multiple Cloud Storage Accounts
      Supported`,

      ],
      buttonText: 'Download',
    },
    {
      plan: 'Premium (2TB)',
      lifespan: '3 Years',
      pricing: '$12.00',
      selected: true,
      text: [
        `Encrypted and Privacy-Preserving
      Storage & Search Functions for 2 TB
      of Users’ Confidential & Privacy
      Preserving Files in Toshiba HDD`,

        `Encrypted and Privacy Preserving
      Files Sync. & Storage Function for
      2 TB Users Confidential Files to
      the User’s Owned Cloud Storage.
      (Google Drive, Dropbox, Box, and
      OneDrive)`,

        `Multiple Cloud Storage Accounts
      Supported`,

      ],
      buttonText: 'Download',
    },
  ]
export default Pricing;
